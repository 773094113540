import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default class About extends Component {
  render() {
    return (
      <div className="contact-us-page">
        <Header />
        <div className="body_content" style={{ background: "#e9e8ff" }}>
          <section class="our-login" style={{ paddingTop: 50 }}>
            <div class="container about-us-page">
              <div class="row">
                <div
                  class="col-lg-11 m-auto wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div class="main-title text-center">
                    <h2 class="title">About Us</h2>
                    <br />
                    <br />
                    <div className="row mob-row">
                      <div className="col-lg-8">
                        <p class="paragraph" style={{ textAlign: "left" }}>
                          Welcome to BLUECHIP JOBS PRIVATE LIMITED, formerly
                          known as Bluechip Jobs, a premier consulting firm with
                          a rich history dating back to 2006. Committed to the
                          principles of excellence, we specialize in crafting
                          perfect profiles and delivering innovative solutions
                          in benchmark practices, trends, policies, and
                          strategies within the realm of Human Resources. <br />
                          <br />
                          <span style={{ color: "#0098da", fontWeight: "700" }}>
                            Our Commitment to Quality:
                          </span>{" "}
                          We take pride in our ISO 9001:2015 certification,
                          showcasing our dedication to maintaining the highest
                          standards in our operations. Additionally, our
                          registration with MSME solidifies our standing as a
                          reliable and agile player in the industry.
                          <br />
                          <br />
                          <span style={{ color: "#0098da", fontWeight: "700" }}>
                            360 Degree Manpower Solutions:
                          </span>{" "}
                          At BLUECHIP JOBS PRIVATE LIMITED, we offer
                          comprehensive manpower solutions encompassing
                          Recruitment, Outsourcing, BPO services, Freelancers,
                          and Survey work, catering to organizations of all
                          sizes across Pan India. From frontline positions to
                          top management roles, we leverage our extensive
                          executive team, robust infrastructure, and over 18+
                          years of experience to meet the diverse needs of our
                          clients from different industries in Pan India.
                        </p>
                      </div>
                      <div className="col-lg-4">
                        <div className="user-profile">
                          <img src="./assets/images/founder.jpg" />
                          <p className="name">
                            <strong>Founder</strong> – Late Shri. R.B.Sambhakar
                          </p>
                          <p className="name">
                            <strong>Co Founder</strong> – Devendra Sambhakar
                            <br />
                            <span style={{ position: "relative", top: 16 }}>
                              Jitendra Sambhakar
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <p class="paragraph" style={{ textAlign: "left" }}>
                      <br />
                      <span style={{ color: "#0098da", fontWeight: "700" }}>
                        National Presence, Local Expertise:
                      </span>{" "}
                      With a physical presence in the heart of Chhattisgarh, our
                      office in Raipur serves as a strategic hub for delivering
                      personalized and effective solutions. Our Pan India
                      footprint extends across various industries, providing us
                      with valuable insights and a proven track record in the
                      recruitment of manpower at all levels.
                      <br />
                      <br />
                      <span style={{ color: "#0098da", fontWeight: "700" }}>
                        Manpower Recruitment & Outsourcing Excellence:
                      </span>{" "}
                      Drawing from our 18+ years of industry experience, we
                      excel in providing end-to-end Manpower Recruitment and
                      outsourcing services. Our commitment to delivering prompt
                      and effective solutions positions us as a trusted partner
                      capable of meeting diverse client requirements within
                      turnaround times. Only company from Central India
                      empanelled with more than 50 companies. The companies
                      empanelled are all either MNCs or companies with a Pan
                      India presence.
                      <br />
                      <br />
                      <span style={{ color: "#0098da", fontWeight: "700" }}>
                        Venturing into E-Commerce Services:
                      </span>{" "}
                      In December 2021, we embraced the future by diversifying
                      into the e-commerce service sector with a new entity
                      BLUECHIP CARES PVT LTD. Our platform boasts 300+
                      categories of multiple services, uniquely powered by
                      Bidding Technology tailored for GIG workers and
                      freelancers. This pioneering initiative has positioned us
                      as a trailblazer in the Indian market.
                      <br />
                      <br />
                      BLUECHIP CARES PVT LTD, an integral part of our group,
                      adds another layer to our commitment to providing
                      exceptional services and making a positive impact in the
                      sectors we operate in. <br />
                      <br />
                      <span style={{ color: "#0098da", fontWeight: "700" }}>
                        Recognized Excellence:
                      </span>{" "}
                      Our new venture Bluechip Cares is proud to be recognized
                      as one of the Top 120 Startup companies of India in the
                      NSA 2022. This accolade reflects our commitment to
                      innovation, growth, and excellence in our endeavour’s.
                      <br />
                      <br />
                      At BLUECHIP JOBS PRIVATE LIMITED, we stand as a beacon of
                      reliability, innovation, and client-centric service. Our
                      journey of over 18+ years is a testament to our unwavering
                      commitment to excellence in every aspect of our business.
                      Join us as we continue to redefine standards and make
                      meaningful contributions to the industries we serve.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}
