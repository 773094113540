import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { message, notification, Spin, Select } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

const { Option } = Select;
export default class ManPowerEnquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      designation: "",
      company_name: "",
      mobile: "",
      email: "",
      company_website: "",
      service_required: [],
      company_attachment: "",
      loading: false,
    };
  }
  save = () => {
    if (!this.state.name) {
      message.warning("Name is required.");
      return;
    }
    if (!this.state.designation) {
      message.warning("Designation is required.");
      return;
    }
    if (!this.state.company_name) {
      message.warning("Company Name is required.");
      return;
    }
    if (!this.state.mobile) {
      message.warning("Mobile no is required.");
      return;
    }
    if (this.state.mobile.length != 10) {
      message.warning("Invalid length of Mobile no.");
      return;
    }
    if (!this.state.email) {
      message.warning("Email Address is required.");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    let isValid = emailPattern.test(this.state.email);
    if (isValid == false) {
      message.warning("Invalid email address format.");
      return;
    }
    let formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("designation", this.state.designation);
    formData.append("company_name", this.state.company_name);
    formData.append("mobile", this.state.mobile);
    formData.append("email", this.state.email);
    formData.append("company_website", this.state.company_website);
    formData.append(
      "service_required",
      JSON.stringify(this.state.service_required)
    );
    formData.append("company_attachment", this.state.company_attachment);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    this.setState({ loading: true });
    axios
      .post(URLs.save_manpower_enquiry, formData, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              name: "",
              designation: "",
              company_name: "",
              mobile: "",
              email: "",
              company_website: "",
              service_required: [],
              company_attachment: "",
              loading: false,
            },
            () =>
              this.openNotification(
                "topRight",
                response.data.message,
                "success"
              )
          );
        } else {
          this.setState({ loading: false });
          this.openNotification("topRight", response.data.message, "warning");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        let messageText = "Something went wrong. Please try again.";
        this.openNotification("topRight", messageText, "warning");
      });
  };

  openNotification = (placement, messageText, type) => {
    if (type == "success") {
      notification.success({
        message: messageText,
        description: <p></p>,
        placement,
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      notification.warning({
        message: messageText,
        description: <p></p>,
        placement,
      });
    }
  };
  onChangeFile = (e) => {
    this.setState({
      company_attachment: e.target.files[0],
    });
  };
  render() {
    return (
      <div className="wrapper ovh login-page">
        <Header />
        <div className="body_content" style={{ background: "#e9e8ff" }}>
          <section class="our-login" style={{ paddingTop: 50 }}>
            <div class="container">
              <div class="row">
                <div
                  class="col-lg-6 m-auto wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div class="main-title text-center">
                    <h2 class="title">Manpower Enquiry</h2>
                    <p class="paragraph">Please submit form for enuiry</p>
                  </div>
                </div>
              </div>
              <div class="row wow fadeInRight" data-wow-delay="300ms">
                <div class="col-xl-6 mx-auto">
                  <Spin spinning={this.state.loading}>
                    <div class="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                      <div class="mb20">
                        <label class="form-label fw600 dark-color">
                          Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="name"
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Designation <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="designation"
                          value={this.state.designation}
                          onChange={(e) =>
                            this.setState({ designation: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Company Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="company_name"
                          value={this.state.company_name}
                          onChange={(e) =>
                            this.setState({ company_name: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Mobile No. <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder=""
                          name="mobile"
                          value={this.state.mobile}
                          onChange={(e) =>
                            this.setState({ mobile: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Company Website (If Available)
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="company_website"
                          value={this.state.company_website}
                          onChange={(e) =>
                            this.setState({ company_website: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Company Profile Attachemnt (If Available)
                          <br />
                          (Only Pdf, Word file)
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          accept=".pdf, .doc, .docx"
                          name="company_attachment"
                          onChange={this.onChangeFile}
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Kind Of Service Required (Can select multiple
                          services)
                        </label>
                        <Select
                          mode="multiple"
                          className="service_required_input"
                          name="service_required"
                          style={{ width: "100%", height: 55 }}
                          value={this.state.service_required}
                          maxTagCount={"responsive"}
                          onChange={(value) =>
                            this.setState(
                              {
                                service_required: value,
                              },
                              () => console.log("value", value)
                            )
                          }
                        >
                          <Option value="Recruitment">Recruitment</Option>
                          <Option value="Outsourcing">Out-Sourcing</Option>
                          <Option value="Recruitment-and-outsourcing">
                            Recruitment & Out-Sourcing
                          </Option>
                          <Option value="BPO">BPO</Option>
                          <Option value="Manpower for survey">
                            Manpower for survey
                          </Option>
                          <Option value="Freelancers">Freelancers</Option>
                        </Select>
                      </div>
                      <div class="d-grid mb20">
                        <button
                          class="ud-btn btn-thm"
                          style={{ backgroundColor: "#0098da" }}
                          type="button"
                          onClick={() => this.save()}
                        >
                          Submit <i class="fal fa-arrow-right-long"></i>
                        </button>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}
