import React, { Component } from "react";

export default class UnderCons extends Component {
  render() {
    return (
      <div class="m-scroll">
        <div class="m-scroll__title">
          <div>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            <h1>
              Welcome to Bluechip Jobs, Since 2006.{" "}
              <span style={{ color: "red" }}>
                An ISO 9001:2015 Certified Company
              </span>
            </h1>
            {/* <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1>
            <h1>Site is under development</h1> */}
          </div>
        </div>
      </div>
    );
  }
}
