import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Spin, Modal, notification, message } from "antd";
import axios from "axios";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";
import moment from "moment";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
} from "react-share";

export default class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experience_list: [],
      list: [],
      loading: false,
      totalJobs: 300,
      position: "",
      job_id: 0,
      dataLoader: false,
      company_name: "",
      experience: "",
      location: "",
      no_of_posts: 0,
      job_description: "",
      date_posted: "",
      qualification: "",
      about_company: "",
      modalStatus: false,

      full_name: "",
      mobile: "",
      email: "",
      current_ctc_to: "",
      current_ctc_from: "",
      experience_f_value: "",
      current_location: "",
      experience_from: "",
      experience_to: "",
      photo: "",
      resume: "",
      formLoader: false,
      industry_name: "",
      d_name: "",
      role_name: "",
      employment_type: "",
      location_list: [],
      ctc_list: [],

      experience_to_show_from: "",
      experience_to_show_to: "",
      ctc_from: "",
      ctc_to: "",
    };
  }
  componentDidMount() {
    this.get_all_list();
    const searchParams = new URLSearchParams(window.location.search);

    // Get individual parameter values
    const param1 = searchParams.get("s");

    // Log or use the parameter values
    if (param1) {
      this.setState(
        {
          job_id: param1,
        },
        () => this.get_job_list()
      );
    } else {
      window.location.assign("/full-time-jobs");
    }
    this.setSelectData();
  }
  setSelectData = () => {
    let experience_list = [];
    let ctc_list = [];
    for (let i = 1; i <= 30; i++) {
      experience_list.push(i);
    }
    for (let i = 0; i <= 100; i++) {
      ctc_list.push(i);
    }
    this.setState({ experience_list, ctc_list });
  };
  get_all_list = () => {
    axios
      .get(URLs.get_all_select_list)
      .then((response) => {
        if (response.data.status == "1") {
          console.log("list", response.data.data);
          this.setState({
            location_list: response.data.data.location,
          });
        }
      })
      .catch((error) => {});
  };
  get_job_list = () => {
    this.setState({ dataLoader: true });
    axios
      .get(URLs.get_job_list)
      .then((response) => {
        if (response.data.status == "1") {
          console.log("respponse", response.data.data);
          for (let item of response.data.data) {
            if (this.state.job_id == item.job_id) {
              let date_posted = moment(item.date_posted).format("DD MMM, YYYY");
              this.setState({
                position: item.position,
                // company_name: item.company_name,
                company_name: "",
                experience_to_show_from: item.experience_from,
                experience_to_show_to: item.experience_to,
                location: item.location,
                dataLoader: false,
                no_of_posts: item.no_of_posts,
                job_description: JSON.parse(item.job_description),
                date_posted,
                qualification: item.qualification,
                about_company: JSON.parse(item.about_company),
                industry_name: item.industry_name,
                d_name: item.d_name,
                role_name: item.role_name,
                employment_type: item.employment_type,
                ctc_from: item.ctc_from,
                ctc_to: item.ctc_to,
              });
              return;
            }
          }
        } else {
          this.setState({ dataLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ dataLoader: false });
      });
  };

  openNotification = (placement, messageText, type) => {
    if (type == "success") {
      notification.success({
        message: messageText,
        description: <p></p>,
        placement,
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      notification.warning({
        message: messageText,
        description: <p></p>,
        placement,
      });
    }
  };
  closeModal = () => {
    this.setState({
      modalStatus: false,
    });
  };
  openModal = () => {
    this.setState({
      modalStatus: true,
    });
  };
  onChangePhoto = (e) => {
    this.setState({
      photo: e.target.files[0],
    });
  };
  onChangeResume = (e) => {
    const allowedExtensions = /(\.doc)$/i; // Regular expression to check if file has .doc extension
    const file = e.target.files[0];
    if (file && allowedExtensions.test(file.name)) {
      this.setState({
        resume: e.target.files[0],
      });
    } else {
      message.warning(
        "Please select .doc file. Other type of files are not allowed."
      );
      return;
    }
  };
  render() {
    return (
      <div className="wrapper ovh home-page">
        <Helmet>
          <meta
            property="og:image"
            content="https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
          />
          <meta
            property="og:title"
            content="Bluechip Jobs Full Time Jobs Details"
          ></meta>
          <meta
            property="og:description"
            content="Bluechip Jobs Full Time Jobs Details"
          ></meta>
          <meta property="og:url" content={window.location.href}></meta>
          <meta property="og:type" content="website"></meta>
        </Helmet>
        <Header />
        <div className="body_content">
          {/* <!-- Breadcumb Sections --> */}
          <section className="breadcumb-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcumb-style1">
                    <div className="breadcumb-list">
                      <a href="#">Home</a>
                      <a href="#">Full Time Jobs</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="backbutton">
                    <a href="/full-time-jobs">
                      <i className="fas fa-chevron-left"></i> &nbsp;Back
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Listings All Lists --> */}
          <section className="pt30 pb90">
            <Spin spinning={this.state.dataLoader}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="service-about">
                      <h4 className="mb-0">{this.state.position}</h4>
                      <div className="review-meta d-flex align-items-center">
                        <p className="mb-0 body-color fz14">
                          {this.state.company_name}
                        </p>
                        &nbsp;&nbsp;
                        {/* <i className="fas fa-star fz10 review-color me-2"></i>
                        <p className="mb-0 body-color fz14">
                          <span className="dark-color me-2">4.82</span>
                        </p> */}
                      </div>
                      <div className="d-flex align-items-start mb50">
                        <div className="list1">
                          <h6>
                            <strong>About Company</strong>
                          </h6>
                          <p className="text mb-0">
                            {parse(this.state.about_company)}
                          </p>
                        </div>
                      </div>
                      <div
                        className="review-meta d-flex align-items-center"
                        style={{ paddingTop: 0 }}
                      >
                        <box-icon
                          name="shopping-bag"
                          size={"20px"}
                          color={"#a8a8a8"}
                        ></box-icon>
                        &nbsp;
                        <p className="mb-0 body-color fz14">
                          <span className="dark-color">
                            {this.state.experience_to_show_from}-
                            {this.state.experience_to_show_to} Yrs
                          </span>
                        </p>
                        &nbsp;&nbsp;&nbsp;
                        <i
                          className="fas fa-map-marker-alt fz10"
                          style={{ fontSize: 17, color: "#b5b3b3" }}
                        ></i>
                        &nbsp;
                        <p className="mb-0 body-color fz14">
                          <span className="dark-color me-2">
                            {this.state.location}
                          </span>
                        </p>
                        ₹&nbsp;
                        <p className="mb-0 body-color fz14">
                          <span className="dark-color">
                            {this.state.ctc_from}-{this.state.ctc_to} Lakhs
                          </span>
                        </p>
                      </div>
                      <hr className="my-2" style={{ background: "#746b6b" }} />
                      <div
                        className="review-meta d-flex align-items-center"
                        style={{ paddingTop: 12 }}
                      >
                        <p className="mb-0 body-color fz14">
                          <span className="dark-color me-2">
                            Posted on: &nbsp;{this.state.date_posted}
                          </span>
                        </p>
                        <p className="mb-0 body-color fz14">
                          <span className="dark-color me-2">
                            Openings: {this.state.no_of_posts}
                          </span>
                        </p>
                      </div>
                      <br />
                      <p className="text mb30 pt-20 pb-10">
                        <h4
                          className="mb-0"
                          style={{
                            paddingBottom: 15,
                            fontSize: 17,
                            fontWeight: "600",
                          }}
                        >
                          Job Description
                        </h4>
                        <div className="job-descr-para">
                          {parse(this.state.job_description)}
                        </div>
                      </p>
                      <p className="text mb-0">
                        <strong>Role:</strong> {this.state.position}
                      </p>
                      <p className="text mb-0">
                        <strong>Industry Type:</strong>{" "}
                        {this.state.industry_name}
                      </p>
                      <p className="text mb-0">
                        <strong>Department:</strong> {this.state.d_name}
                      </p>
                      <p className="text mb-0">
                        <strong>Employment Type:</strong>{" "}
                        {this.state.employment_type}
                      </p>
                      <p className="text mb30">
                        <strong>Role Category:</strong> {this.state.role_name}
                      </p>
                      <p className="text mb30">
                        <strong>Education:</strong> {this.state.qualification}
                      </p>

                      <center>
                        <a
                          class="ud-btn btn-home3 add-joining"
                          href="#!"
                          onClick={() =>
                            window.location.assign(
                              "/apply-job?job_id=" + this.state.job_id
                            )
                          }
                        >
                          Apply{" "}
                        </a>
                      </center>
                      <div className="social-share-with-text">
                        <div className="social-share-links">
                          <FacebookShareButton
                            imageUrl={
                              "https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
                            }
                            title={this.state.position}
                            url={
                              window.location.href +
                              "&imageUrl=https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
                            }
                          >
                            <img src="./assets/image/social/facebook.png" />
                          </FacebookShareButton>

                          <LinkedinShareButton
                            imageUrl={
                              "https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
                            }
                            title={this.state.position}
                            url={
                              window.location.href +
                              "&imageUrl=https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
                            }
                          >
                            <img
                              src="./assets/image/social/linkedin.png"
                              style={{ width: 26 }}
                            />
                          </LinkedinShareButton>

                          {/* <a
                                        target="_blank"
                                        href={`https://www.instagram.com/send?text=${encodeURIComponent(
                                          url
                                        )}`}
                                      >
                                        <img src="./assets/image/social/instagram.png" />
                                      </a> */}

                          <TelegramShareButton
                            imageUrl={
                              "https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
                            }
                            title={this.state.position}
                            url={
                              window.location.href +
                              "&imageUrl=https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
                            }
                          >
                            <img
                              src="./assets/image/social/telegram.png"
                              style={{ width: 26 }}
                            />
                          </TelegramShareButton>
                        </div>
                      </div>
                      <hr className="opacity-100 mb60" />
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </section>
          <Footer />

          {/* Apply modal  */}
          <Modal
            title={"Apply for " + this.state.position + " Position"}
            visible={this.state.modalStatus}
            onOk={() => this.apply()}
            onCancel={() => this.closeModal()}
            okText="Apply"
            cancelText="Close"
            style={{
              width: 600,
            }}
            width={500}
          >
            <Spin spinning={this.state.formLoader}>
              <div>
                <div class="mt30 mb20">
                  <label class="form-label fw600 dark-color">
                    Full Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    name="full_name"
                    value={this.state.full_name}
                    onChange={(e) =>
                      this.setState({ full_name: e.target.value })
                    }
                  />
                </div>
                <div class="mt20 mb20">
                  <label class="form-label fw600 dark-color">
                    Mobile <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder=""
                    name="mobile"
                    maxLength={10}
                    value={this.state.mobile}
                    onChange={(e) => {
                      let value = e.target.value.slice(0, 10);
                      this.setState({ mobile: value });
                    }}
                  />
                </div>
                <div class="mt20 mb20">
                  <label class="form-label fw600 dark-color">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder=""
                    name="email"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
                <div class="mt20 mb20">
                  <label class="form-label fw600 dark-color">
                    Current CTC (In lakhs)<span className="text-danger">*</span>
                  </label>
                  <div className="d-flex justify-content-between">
                    <select
                      class="form-control"
                      name="state_id"
                      value={this.state.current_ctc_from}
                      onChange={(e) => {
                        let value = e.target.value;
                        this.setState(
                          {
                            current_ctc_from: e.target.value,
                          },
                          () => {
                            if (value == "negotiable") {
                              this.setState({
                                current_ctc_to: "",
                              });
                            }
                          }
                        );
                      }}
                      style={{ width: "45%" }}
                    >
                      <option value="">Select</option>
                      <option value="negotiable">Negotiable</option>
                      {this.state.ctc_list.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item} Lakh
                          </option>
                        );
                      })}
                      <option value="100+">100+ Lakhs</option>
                    </select>
                    <div className="pt-3 text-center"></div>
                    <select
                      class="form-control"
                      name="state_id"
                      value={this.state.current_ctc_to}
                      onChange={(e) => {
                        this.setState({
                          current_ctc_to: e.target.value,
                        });
                      }}
                      disabled={
                        this.state.current_ctc_from == "negotiable"
                          ? true
                          : false
                      }
                      style={{ width: "45%" }}
                    >
                      <option value="">Select</option>
                      {this.state.ctc_list.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            {item} Thousand
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div class="mt20 mb20">
                  <label class="form-label fw600 dark-color">
                    Experience <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex justify-content-between">
                    <select
                      class="form-control"
                      name="experience"
                      value={this.state.experience_from}
                      onChange={(e) => {
                        this.setState({
                          experience_from: e.target.value,
                        });
                      }}
                      style={{ width: "45%" }}
                    >
                      <option value="">Select</option>
                      {this.state.experience_list &&
                        this.state.experience_list.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item} Year
                            </option>
                          );
                        })}
                    </select>
                    <div className="text-center pt-3"></div>
                    <select
                      class="form-control"
                      name="experience"
                      value={this.state.experience_to}
                      onChange={(e) => {
                        this.setState({
                          experience_to: e.target.value,
                        });
                      }}
                      style={{ width: "45%" }}
                    >
                      <option value="">Select</option>
                      {this.state.experience_list &&
                        this.state.experience_list.map((item, index) => {
                          return (
                            <option value={item} key={index}>
                              {item} Month
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div class="mt20 mb20">
                  <label class="form-label fw600 dark-color">
                    Current Location <span className="text-danger">*</span>
                  </label>
                  <select
                    class="form-control"
                    name="experience"
                    value={this.state.current_location}
                    onChange={(e) => {
                      this.setState({
                        current_location: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select</option>
                    {this.state.location_list &&
                      this.state.location_list.map((item, index) => {
                        return (
                          <option value={item.location_id} key={index}>
                            {item.location_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div class="mt20 mb20">
                  <label class="form-label fw600 dark-color">
                    Photo(If Available)
                    <br />
                    (Only JPG, PNG)
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    accept=".jpg, .png"
                    name="photo"
                    onChange={this.onChangePhoto}
                  />
                </div>
                <div class="mt20 mb20">
                  <label class="form-label fw600 dark-color">
                    Resume <span className="text-danger">*</span>
                    <br />
                    (Only Word File)
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    accept=".doc, .docx"
                    name="resume"
                    onChange={this.onChangeResume}
                  />
                </div>
              </div>
            </Spin>
          </Modal>
        </div>
      </div>
    );
  }
}
