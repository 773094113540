import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Spin, Slider, Checkbox } from "antd";
import axios from "axios";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";
import { Helmet } from "react-helmet";
import moment from "moment";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
} from "react-share";

export default class JobByCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      clist: [],
      loading: false,
      totalJobs: 300,
      selectedExperience: 0,
      salary_list: [
        "0-3 Lakhs",
        "3-6 Lakhs",
        "6-10 Lakhs",
        "10-15 Lakhs",
        "15-25 Lakhs",
        "25-50 Lakhs",
        "50-75 Lakhs",
        "75-100 Lakhs",
      ],
      searchText: "",
      department_list: [],
      industry_list: [],
      selected_departments: [],
      selected_salaries: [],
      industry_id: "",
      industry_show_name: "",
    };
  }
  componentDidMount() {
    this.get_all_list();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("id");
    if (id) {
      this.setState(
        {
          industry_id: id,
        },
        () => this.get_job_list()
      );
    } else {
      window.location.assign("/");
    }
  }
  get_all_list = () => {
    axios
      .get(URLs.get_all_select_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            department_list: response.data.data.department,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  get_job_list = () => {
    this.setState({ loading: true });
    let params = {
      industry_id: this.state.industry_id,
    };
    axios
      .post(URLs.get_list_by_category_id, params)
      .then((response) => {
        if (response.data.status == "1") {
          console.log("job list", response.data.data);
          this.setState({
            list: response.data.data,
            clist: response.data.data,
            industry_show_name: response.data.name,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  onChangeExperience = (newValue) => {
    this.setState(
      {
        selectedExperience: newValue,
      },
      () => this.search()
    );
  };
  onChangeCheckDepartment = (e) => {
    let newArr = this.state.selected_departments;
    if (newArr.includes(e.target.value)) {
      let arr = [];
      for (let item of newArr) {
        if (item == e.target.value) {
        } else {
          arr.push(item);
        }
      }
      newArr = arr;
    } else {
      newArr.push(e.target.value);
    }
    this.setState(
      {
        selected_departments: newArr,
      },
      () => this.search()
    );
  };
  onChangeSalary = (e) => {
    let newArr = this.state.selected_salaries;
    if (newArr.includes(e.target.value)) {
      let arr = [];
      for (let item of newArr) {
        if (item == e.target.value) {
        } else {
          arr.push(item);
        }
      }
      newArr = arr;
    } else {
      newArr.push(e.target.value);
    }
    this.setState(
      {
        selected_salaries: newArr,
      },
      () => this.search()
    );
  };
  search = () => {
    let searchText = this.state.searchText;
    let list = this.state.clist;
    let filtered_arr = [];
    // filter by search
    if (searchText) {
      for (let item of list) {
        if (
          (item.position &&
            item.position.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.company_name &&
            item.company_name
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (item.location &&
            item.location.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.d_name &&
            item.d_name.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.about_company &&
            item.about_company
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (item.job_description &&
            item.job_description
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (item.industry_name &&
            item.industry_name
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (item.role_name &&
            item.role_name.toLowerCase().includes(searchText.toLowerCase())) ||
          (item.role_name &&
            item.employment_type
              .toLowerCase()
              .includes(searchText.toLowerCase()))
        ) {
          filtered_arr.push(item);
        }
      }
    } else {
      filtered_arr = list;
    }
    // filter by experience
    let experience_f_arr = [];
    if (this.state.selectedExperience) {
      let arr = [];
      for (let item of filtered_arr) {
        // 6<=7 && 8>=7
        if (
          Number(item.experience_from) <=
            Number(this.state.selectedExperience) &&
          Number(item.experience_to) >= Number(this.state.selectedExperience)
        ) {
          arr.push(item);
        }
        // if (
        //   Number(item.experience_from) <=
        //     Number(this.state.selectedExperience) ||
        //   Number(item.experience_to) <= Number(this.state.selectedExperience)
        // ) {
        //   arr.push(item);
        // }
      }
      experience_f_arr = arr;
    } else {
      experience_f_arr = filtered_arr;
    }

    let department_arr = [];
    if (this.state.selected_departments.length > 0) {
      let arr = [];
      let selected_departments = this.state.selected_departments;

      for (let item of experience_f_arr) {
        if (selected_departments.includes(item.department_id)) {
          arr.push(item);
        }
      }
      department_arr = arr;
    } else {
      department_arr = experience_f_arr;
    }

    let salary_f_arr = [];
    if (this.state.selected_salaries.length > 0) {
      let arr = [];
      let selected_salaries = this.state.selected_salaries;

      for (let item of department_arr) {
        if (selected_salaries.includes(item.ctc)) {
          arr.push(item);
        }
      }
      salary_f_arr = arr;
    } else {
      salary_f_arr = department_arr;
    }
    this.setState({ list: salary_f_arr });
  };
  shareOnFacebook = (url) => {
    // var url = window.location.href;
    var url = "https://bluechipjobs.co.in/" + url;

    // Open a new window with the Facebook share dialog
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url),
      "facebook-share-dialog",
      "width=626,height=436"
    );
  };
  render() {
    return (
      <div className="wrapper ovh home-page">
        <Helmet>
          <meta
            property="og:image"
            content="https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
          />
          <meta
            property="og:title"
            content="Bluechip Jobs Full Time Jobs"
          ></meta>
          <meta
            property="og:description"
            content="Bluechip Jobs Full Time Jobs"
          ></meta>
          <meta
            property="og:url"
            content="https://bluechipjobs.co.in/full-time-jobs"
          ></meta>
          <meta property="og:type" content="website"></meta>
        </Helmet>
        <Header />
        <div className="body_content">
          {/* <!-- Breadcumb Sections --> */}
          <section className="breadcumb-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcumb-style1">
                    <div className="breadcumb-list">
                      <a href="#">Home</a>
                      <a href="#">Full Time Jobs</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Listings All Lists --> */}
          <section className="pt30 pb90">
            <div className="container" style={{ marginBottom: 20 }}>
              <div className="row">
                <div className="col-lg-9 mb15">
                  <h2>
                    Job According To The {this.state.industry_show_name}{" "}
                    Category
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 mb15"></div>
                <div className="col-lg-9"></div>
              </div>
            </div>
            <Spin spinning={this.state.loading}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="list-sidebar-style1 d-none d-lg-block">
                      <div className="accordion" id="accordionExample">
                        <div className="card mb20 pb0">
                          <div className="card-header active" id="heading1">
                            <h4>
                              <button
                                className="btn btn-link ps-0"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse1"
                                aria-expanded="true"
                                aria-controls="collapse1"
                                style={{
                                  fontSize: 19,
                                  position: "relative",
                                  top: -6,
                                }}
                              >
                                Experience
                              </button>
                            </h4>
                          </div>
                          <div
                            id="collapse1"
                            className="collapse show"
                            aria-labelledby="heading1"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body card-body px-0 pt-0">
                              {/* <!-- Range Slider Desktop Version --> */}
                              <div className="range-slider-style1">
                                <div className="range-wrapper">
                                  <Slider
                                    min={1}
                                    max={20}
                                    onChange={this.onChangeExperience}
                                    value={
                                      this.state.selectedExperience
                                        ? this.state.selectedExperience
                                        : 0
                                    }
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      padding: "0 8px",
                                      position: "relative",
                                      top: -10,
                                    }}
                                  >
                                    <div>0 Yrs</div>
                                    <div style={{ color: "#000" }}>
                                      {this.state.selectedExperience} Yrs
                                    </div>
                                    <div>30 Yrs</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card mb20 pb5">
                          <div className="card-header active" id="heading2">
                            <h4>
                              <button
                                className="btn btn-link ps-0"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse2"
                                aria-expanded="true"
                                aria-controls="collapse2"
                              >
                                Department
                              </button>
                            </h4>
                          </div>
                          <div
                            id="collapse2"
                            className="collapse show"
                            aria-labelledby="heading2"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body card-body px-0 pt-0">
                              <div className="checkbox-style1 mb15">
                                {this.state.department_list.length > 0 &&
                                  this.state.department_list.map(
                                    (item, index) => {
                                      return (
                                        <label
                                          key={index}
                                          className="custom_checkbox pl-0"
                                          style={{ fontSize: 14 }}
                                        >
                                          <Checkbox
                                            onChange={
                                              this.onChangeCheckDepartment
                                            }
                                            value={item.department_id}
                                          >
                                            {item.d_name}
                                          </Checkbox>
                                          {/* <span className="right-tags">(1,945)</span> */}
                                        </label>
                                      );
                                    }
                                  )}
                              </div>
                              {/* <a className="text-thm" href="#">
                                +20 more
                              </a> */}
                            </div>
                          </div>
                        </div>
                        {/* <div className="card mb20 pb5">
                          <div className="card-header active" id="heading4">
                            <h4>
                              <button
                                className="btn btn-link ps-0"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapse4"
                                aria-expanded="true"
                                aria-controls="collapse4"
                              >
                                Salary
                              </button>
                            </h4>
                          </div>
                          <div
                            id="collapse4"
                            className="collapse show"
                            aria-labelledby="heading4"
                            data-parent="#accordionExample"
                          >
                            <div className="card-body card-body px-0 pt-0">
                              <div className="checkbox-style1 mb15">
                                {this.state.salary_list.map((item, index) => {
                                  return (
                                    <label
                                      className="custom_checkbox pl-0"
                                      key={index}
                                    >
                                      <Checkbox
                                        onChange={this.onChangeSalary}
                                        value={item}
                                      >
                                        {item}
                                      </Checkbox>
                                      <span className="right-tags">
                                        (1,945)
                                      </span>
                                    </label>
                                  );
                                })}
                              </div>
                              <a className="text-thm" href="#">
                                +20 more
                              </a>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="row align-items-center mb20">
                      <div className="col-md-12">
                        <div className="search_area mb15">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="What are you looking for?"
                            value={this.state.searchText}
                            onChange={(e) =>
                              this.setState({
                                searchText: e.target.value,
                              })
                            }
                          />
                          <label
                            style={{ cursor: "pointer" }}
                            onClick={() => this.search()}
                          >
                            <span className="flaticon-loupe"></span>
                          </label>
                        </div>
                        <div className="text-center text-md-start">
                          <p className="text mb-0 mb10-sm">
                            <span className="fw500">
                              {this.state.list.length}
                            </span>{" "}
                            Jobs available
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="page_control_shorting d-md-flex align-items-center justify-content-center justify-content-md-end">
                          <div className="dropdown-lists d-block d-lg-none me-2 mb10-sm">
                            <ul className="p-0 mb-0 text-center text-md-start">
                              <li>
                                {/* <!-- Advance Features modal trigger --> */}
                                <button
                                  type="button"
                                  className="open-btn filter-btn-left"
                                >
                                  {" "}
                                  <img
                                    className="me-2"
                                    src="images/icon/all-filter-icon.svg"
                                    alt=""
                                  />{" "}
                                  All Filter
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="pcs_dropdown dark-color pr10 text-center text-md-end"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {this.state.list.length > 0 &&
                        this.state.list.map((item, index) => {
                          let url =
                            `https://bluechipjobs.co.in/job-details?s=${item.job_id}` +
                            "&imageUrl=https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg";
                          return (
                            <div className="col-sm-12 col-xl-12" key={index}>
                              <div
                                className="listing-style1"
                                style={{
                                  boxShadow: "0 6px 12px rgba(30,10,58,.04)",
                                }}
                              >
                                <div className="list-content">
                                  <h5 className="list-title mb-0">
                                    <a href={`/job-details?s=${item.job_id}`}>
                                      {item.position}
                                    </a>
                                  </h5>
                                  <div className="review-meta d-flex align-items-center"></div>
                                  <hr className="my-2" />
                                  <div className="review-meta d-flex align-items-center">
                                    <box-icon
                                      name="shopping-bag"
                                      size={"20px"}
                                      color={"#a8a8a8"}
                                    ></box-icon>
                                    &nbsp;
                                    <p className="mb-0 body-color fz14">
                                      <span className="dark-color">
                                        {item.experience_from}-
                                        {item.experience_to} Yrs
                                      </span>
                                    </p>
                                    &nbsp;&nbsp;&nbsp;
                                    <i
                                      className="fas fa-map-marker-alt fz10"
                                      style={{ fontSize: 17, color: "#b5b3b3" }}
                                    ></i>
                                    &nbsp;
                                    <p className="mb-0 body-color fz14">
                                      <span className="dark-color me-2">
                                        {item.location}
                                      </span>
                                    </p>
                                    &nbsp;&nbsp;
                                    <i
                                      className="fas fa-user-tie fz10"
                                      style={{ fontSize: 17 }}
                                    ></i>
                                    &nbsp;
                                    <p className="mb-0 body-color fz14">
                                      <span className="dark-color me-2">
                                        {item.no_of_posts} Posts
                                      </span>
                                    </p>
                                    ₹&nbsp;
                                    <p className="mb-0 body-color fz14">
                                      <span className="dark-color">
                                        {item.ctc_from}-{item.ctc_to} Lakhs
                                      </span>
                                    </p>
                                  </div>
                                  <hr className="my-1" />
                                  <div className="review-meta d-flex align-items-center">
                                    <p className="mb-0 body-color fz14">
                                      <span className="dark-color">
                                        {item.short_job_description.substring(
                                          0,
                                          90
                                        )}
                                        ...
                                      </span>
                                    </p>
                                    <br />
                                  </div>
                                  <div className="social-share-with-text">
                                    <p className="mb-0 body-color fz14">
                                      <span className="dark-color">
                                        <strong>Date:</strong>{" "}
                                        {item.date_posted
                                          ? moment(item.date_posted).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "---"}
                                      </span>
                                    </p>
                                    <div className="social-share-links">
                                      <FacebookShareButton
                                        imageUrl={
                                          "https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
                                        }
                                        title={item.position}
                                        url={`https://bluechipjobs.co.in/job-details?s=${item.job_id}&url=https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg`}
                                      >
                                        <img src="./assets/image/social/facebook.png" />
                                      </FacebookShareButton>

                                      <LinkedinShareButton
                                        imageUrl={
                                          "https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
                                        }
                                        title={item.position}
                                        url={url}
                                      >
                                        <img
                                          src="./assets/image/social/linkedin.png"
                                          style={{ width: 26 }}
                                        />
                                      </LinkedinShareButton>

                                      {/* <a
                                        target="_blank"
                                        href={`https://www.instagram.com/send?text=${encodeURIComponent(
                                          url
                                        )}`}
                                      >
                                        <img src="./assets/image/social/instagram.png" />
                                      </a> */}

                                      <TelegramShareButton
                                        imageUrl={
                                          "https://bluechipjobs.co.in/assets/bluechipjobs-logo.jpg"
                                        }
                                        title={item.position}
                                        url={url}
                                      >
                                        <img
                                          src="./assets/image/social/telegram.png"
                                          style={{ width: 26 }}
                                        />
                                      </TelegramShareButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    {/* <div className="row">
                      <div className="mbp_pagination mt30 text-center">
                        <ul className="page_navigation">
                          <li className="page-item">
                            <a className="page-link" href="#">
                              {" "}
                              <span className="fas fa-angle-left"></span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li className="page-item active" aria-current="page">
                            <a className="page-link" href="#">
                              2 <span className="sr-only">(current)</span>
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              4
                            </a>
                          </li>
                          <li className="page-item d-inline-block d-sm-none">
                            <a className="page-link" href="#">
                              ...
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              5
                            </a>
                          </li>
                          <li className="page-item d-none d-sm-inline-block">
                            <a className="page-link" href="#">
                              ...
                            </a>
                          </li>
                          <li className="page-item d-none d-sm-inline-block">
                            <a className="page-link" href="#">
                              20
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              <span className="fas fa-angle-right"></span>
                            </a>
                          </li>
                        </ul>
                        <p className="mt10 mb-0 pagination_page_count text-center">
                          1 – {this.state.list.length} of {this.state.totalJobs}
                          + jobs available
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </Spin>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}
