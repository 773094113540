import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";
import axios from "axios";
import { Spin } from "antd";

export default class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.get_company_list();
  }
  get_company_list = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_company_list)
      .then((response) => {
        if (response.data.status == "1") {
          let arr = [];
          for (let item of response.data.data) {
            let image_url = BASE_URL + "/" + item.logo_file_name;
            item.image_url = image_url;
            arr.push(item);
          }
          this.setState({
            companies: arr,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        // console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="wrapper ovh home-page">
        <Header />
        <div className="body_content">
          {/* <!-- Breadcumb Sections --> */}
          <section class="breadcumb-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="breadcumb-style1">
                    <div class="breadcumb-list">
                      <a href="#">Home</a>&nbsp;&nbsp;&nbsp;
                      <a href="#">Companies</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Popular Services --> */}
          <section class="pb40-md pb70 mt0-lg" style={{ paddingTop: 40 }}>
            <Spin spinning={this.state.loading}>
              <div class="container">
                <div class="row align-items-center wow fadeInUp">
                  <div class="col-lg-9">
                    <div class="main-title mb30-lg">
                      <h2 class="title">All Companies</h2>
                    </div>
                  </div>
                  <div class="col-lg-3"></div>
                </div>
                <div class="row">
                  {this.state.companies.map((item, index) => {
                    return (
                      <div class="col-sm-6 col-xl-3" key={index}>
                        <div class="listing-style1 style5">
                          <div class="list-thumb">
                            <img class="w-100" src={item.image_url} alt="" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Spin>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}
