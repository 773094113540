import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Spin, Modal, Button } from "antd";
import axios from "axios";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";

export default class Csr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: false,
      modalStatus: false,
      singleItem: {},
    };
  }
  componentDidMount() {
    this.get_service_list();
  }
  get_service_list = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_csr_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            list: response.data.data,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  openModal = (item) => {
    this.setState({
      modalStatus: true,
      singleItem: item,
    });
  };
  render() {
    return (
      <div className="wrapper ovh home-page">
        <Header />
        <div className="body_content">
          {/* <!-- Breadcumb Sections --> */}
          <section class="breadcumb-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="breadcumb-style1">
                    <div class="breadcumb-list">
                      <a href="#">Home</a>
                      <a href="#">CSR</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Listings All Lists --> */}
          <section class="pt0 pb90">
            <div class="container">
              <Spin spinning={this.state.loading}>
                <div class="row mt35">
                  <div class="col-lg-12 mb15">
                    <h2>CSR</h2>
                  </div>
                  {this.state.list.map((item, index) => {
                    let url = BASE_URL + "/" + item.image;
                    return (
                      <div class="col-sm-6 col-xl-3" key={index}>
                        <a href={`#!`} onClick={() => this.openModal(item)}>
                          <div class="listing-style1">
                            <div class="list-thumb">
                              <img class="w-100" src={url} alt="" />
                            </div>
                            <div
                              class="list-content"
                              style={{ minHeight: 131 }}
                            >
                              <p
                                class="list-text body-color mb-1"
                                style={{
                                  color: "#000",
                                  fontSize: 15,
                                }}
                              >
                                {item.description.substring(0, 70) + "..."}
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </Spin>
            </div>
          </section>
          <Footer />

          <Modal
            title="Description"
            visible={this.state.modalStatus}
            footer={[
              <Button
                key="1"
                onClick={() => this.setState({ modalStatus: false })}
              >
                Close
              </Button>,
            ]}
            onCancel={() => this.setState({ modalStatus: false })}
            cancelText={"Close"}
            width={400}
          >
            {this.state.singleItem && (
              <p>{this.state.singleItem.description}</p>
            )}
          </Modal>
        </div>
      </div>
    );
  }
}
