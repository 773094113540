import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { message, notification, Spin, Select } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class SubmitResume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      experience_from: "",
      experience_to: "",
      qualification: "",
      industry: "",
      department: "",
      role: "",
      photo: "",
      loading: false,
      state_list: [],
      city_list: [],
      ctc: "",
      ctc_from: "",
      ctc_to: "",

      department_list: [],
      role_list: [],
      industry_list: [],
      ctc_list: [],
      ex_months: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      ex_year_list: [],
      experience_from: "",
      experience_in_smonth: "",
      resume: "",
      location_list: [],
      location_id: "",
    };
  }
  componentDidMount() {
    this.get_all_list();
    let arr = [];
    for (let i = 1; i <= 30; i++) {
      arr.push(i);
    }
    let ctc_list = [];
    for (let i = 0; i <= 100; i++) {
      ctc_list.push(i);
    }
    this.setState({
      ex_year_list: arr,
      ctc_list: ctc_list,
    });
  }
  get_all_list = () => {
    axios
      .get(URLs.get_all_select_list)
      .then((response) => {
        if (response.data.status == "1") {
          console.log("respponse", response.data.data);
          this.setState({
            department_list: response.data.data.department,
            industry_list: response.data.data.industry,
            role_list: response.data.data.role,
            location_list: response.data.data.location,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  submitForm = () => {
    if (!this.state.name) {
      message.warning("Name is required.");
      return;
    }
    if (!this.state.email) {
      message.warning("Email Address is required.");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    let isValid = emailPattern.test(this.state.email);
    if (isValid == false) {
      message.warning("Invalid email address format.");
      return;
    }
    if (!this.state.mobile) {
      message.warning("Mobile number is required.");
      return;
    }
    if (!this.state.ctc_from) {
      message.warning("Please select Current Ctc option");
      return;
    } else if (!this.state.ctc_to) {
      message.warning("Please select Current Ctc option");
      return;
    }
    if (!this.state.qualification) {
      message.warning("Please enter your qualification.");
      return;
    }
    if (!this.state.experience_from) {
      message.warning("Please select experience in year");
      return;
    }
    if (!this.state.experience_to) {
      message.warning("Please select experience in month");
      return;
    }
    if (!this.state.industry) {
      message.warning("Please select industry name");
      return;
    }
    if (!this.state.department) {
      message.warning("Please select department name");
      return;
    }
    if (!this.state.role) {
      message.warning("Please select role name");
      return;
    }
    if (!this.state.location_id) {
      message.warning("Please select location");
      return;
    }
    if (!this.state.resume) {
      message.warning("Please select resume");
      return;
    }

    let formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("mobile", this.state.mobile);
    formData.append("email", this.state.email);
    formData.append("current_ctc_from", this.state.ctc_from);
    formData.append("current_ctc_to", this.state.ctc_to);
    formData.append("qualification", this.state.qualification);
    formData.append("experience_from", this.state.experience_from);
    formData.append("experience_to", this.state.experience_to);
    formData.append("industry_id", this.state.industry);
    formData.append("department_id", this.state.department);
    formData.append("role_id", this.state.role);
    formData.append("location_id", this.state.location_id);
    formData.append("photo", this.state.photo);
    formData.append("resume", this.state.resume);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    this.setState({ loading: true });
    axios
      .post(URLs.save_resume, formData, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              name: "",
              mobile: "",
              email: "",
              state_id: "",
              city_id: "",
              message_text: "",
              loading: false,
            },
            () => {
              this.openNotification(
                "topRight",
                response.data.message,
                "success"
              );
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          );
        } else {
          this.setState({ loading: false });
          this.openNotification("topRight", response.data.message, "warning");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        let messageText = "Something went wrong. Please try again.";
        this.openNotification("topRight", messageText, "warning");
      });
  };

  openNotification = (placement, messageText, type) => {
    if (type == "success") {
      notification.success({
        message: messageText,
        description: <p></p>,
        placement,
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      notification.warning({
        message: messageText,
        description: <p></p>,
        placement,
      });
    }
  };
  onChangeFile = (e) => {
    this.setState({
      photo: e.target.files[0],
    });
  };
  render() {
    return (
      <div className="contact-us-page">
        <Header />
        <div className="body_content" style={{ background: "#e9e8ff" }}>
          <section class="our-login">
            <div class="container">
              <div class="row">
                <div
                  class="col-lg-6 m-auto wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div class="main-title text-center">
                    <h2 class="title">Resume</h2>
                    {/* <p class="paragraph">For any issue you can contact us</p> */}
                  </div>
                </div>
              </div>
              <div class="row wow fadeInRight" data-wow-delay="300ms">
                <div class="col-xl-6 mx-auto">
                  <Spin spinning={this.state.loading}>
                    <div class="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                      <div class="mb20">
                        <label class="form-label fw600 dark-color">
                          Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="name"
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Mobile No. <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder=""
                          name="mobile"
                          value={this.state.mobile}
                          onChange={(e) =>
                            this.setState({ mobile: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Current CTC (In lakhs)
                          <span className="text-danger">*</span>
                        </label>
                        <div className="d-flex justify-content-between">
                          <select
                            class="form-control"
                            name="state_id"
                            value={this.state.ctc_from}
                            onChange={(e) => {
                              let value = e.target.value;
                              this.setState(
                                {
                                  ctc_from: e.target.value,
                                },
                                () => {
                                  if (value == "negotiable") {
                                    this.setState({
                                      ctc_to: "",
                                    });
                                  }
                                }
                              );
                            }}
                            style={{ width: "45%" }}
                          >
                            <option value="">Select (In Lakh)</option>
                            <option value="negotiable">Negotiable</option>
                            {this.state.ctc_list.map((item, index) => {
                              return (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              );
                            })}
                            <option value="100+">100+</option>
                          </select>
                          <div className="pt-3 text-center"></div>
                          <select
                            class="form-control"
                            name="state_id"
                            value={this.state.ctc_to}
                            onChange={(e) => {
                              this.setState({
                                ctc_to: e.target.value,
                              });
                            }}
                            disabled={
                              this.state.ctc_from == "negotiable" ? true : false
                            }
                            style={{ width: "45%" }}
                          >
                            <option value="">Select (In Thousand)</option>
                            {this.state.ctc_list.map((item, index) => {
                              return (
                                <option value={item} key={index}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Experience <span className="text-danger">*</span>
                        </label>
                        <div className="d-flex justify-content-between">
                          <select
                            class="form-control"
                            name="experience_from"
                            value={this.state.c}
                            onChange={(e) => {
                              this.setState({
                                experience_from: e.target.value,
                              });
                            }}
                            style={{ width: "45%" }}
                          >
                            <option value="">Select Year</option>
                            {this.state.ex_year_list &&
                              this.state.ex_year_list.map((item, index) => {
                                return (
                                  <option value={item} key={index}>
                                    {item} Year
                                  </option>
                                );
                              })}
                          </select>
                          <div className="text-center pt-3"></div>
                          <select
                            class="form-control"
                            name="experience_from"
                            value={this.state.experience_to}
                            onChange={(e) => {
                              this.setState({
                                experience_to: e.target.value,
                              });
                            }}
                            style={{ width: "45%" }}
                          >
                            <option value="">Select Month</option>
                            {this.state.ex_year_list &&
                              this.state.ex_year_list.map((item, index) => {
                                return (
                                  <option value={item} key={index}>
                                    {item} Month
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Qualification <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="mobile"
                          value={this.state.qualification}
                          onChange={(e) =>
                            this.setState({ qualification: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Industry <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          name="state_id"
                          value={this.state.industry}
                          onChange={(e) => {
                            this.setState({
                              industry: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select</option>
                          {this.state.industry_list &&
                            this.state.industry_list.map((item, index) => {
                              return (
                                <option value={item.industry_id} key={index}>
                                  {item.industry_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Department <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          name="state_id"
                          value={this.state.department}
                          onChange={(e) => {
                            this.setState({
                              department: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select</option>
                          {this.state.department_list &&
                            this.state.department_list.map((item, index) => {
                              return (
                                <option value={item.department_id} key={index}>
                                  {item.d_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Role <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          name="state_id"
                          value={this.state.role}
                          onChange={(e) => {
                            this.setState({
                              role: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select</option>
                          {this.state.role_list &&
                            this.state.role_list.map((item, index) => {
                              return (
                                <option value={item.role_id} key={index}>
                                  {item.role_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Location <span className="text-danger">*</span>
                        </label>
                        <select
                          class="form-control"
                          name="state_id"
                          value={this.state.location_id}
                          onChange={(e) => {
                            this.setState({
                              location_id: e.target.value,
                            });
                          }}
                        >
                          <option value="">Select</option>
                          {this.state.location_list &&
                            this.state.location_list.map((item, index) => {
                              return (
                                <option value={item.location_id} key={index}>
                                  {item.location_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Photo (If Available) (Only JPG, PNG file)
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          accept="image/*"
                          name="photo"
                          onChange={this.onChangeFile}
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Resume <span className="text-danger">*</span>
                          (Only Word file)
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          accept=".doc, .docx"
                          name="resume"
                          onChange={(e) => {
                            const allowedExtensions = /(\.doc)$/i; // Regular expression to check if file has .doc extension
                            const file = e.target.files[0];
                            if (file && allowedExtensions.test(file.name)) {
                              this.setState({
                                resume: e.target.files[0],
                              });
                            } else {
                              message.warning(
                                "Please select .doc file. Other type of files are not allowed."
                              );
                              return;
                            }
                          }}
                        />
                      </div>
                      <div class="d-grid mb20">
                        <button
                          class="ud-btn btn-thm"
                          style={{ backgroundColor: "#0098da" }}
                          type="button"
                          onClick={() => this.submitForm()}
                        >
                          Submit <i class="fal fa-arrow-right-long"></i>
                        </button>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}
