import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import URLs from "./utils/apis";
import { BASE_URL } from "./utils/consts";
import axios from "axios";
import { Spin } from "antd";

export default class AllCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      loading: false,
    };
  }
  componentDidMount() {
    this.get_category_list();
  }
  get_category_list = () => {
    this.setState({ categoryLoader: true });
    axios
      .get(URLs.get_home_page_industry_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            categories: response.data.data,
            categoryLoader: false,
          });
        } else {
          this.setState({ loading: false, categoryLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false, categoryLoader: false });
        // console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div className="wrapper ovh home-page">
        <Header />
        <div className="body_content">
          {/* <!-- Breadcumb Sections --> */}
          <section class="breadcumb-section">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="breadcumb-style1">
                    <div class="breadcumb-list">
                      <a href="#">Home</a>&nbsp;&nbsp;&nbsp;
                      <a href="#">categories</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Popular Services --> */}
          <section class="pb40-md pb70 mt0-lg" style={{ paddingTop: 40 }}>
            <Spin spinning={this.state.loading}>
              <div class="container">
                <div class="row align-items-center wow fadeInUp">
                  <div class="col-lg-9">
                    <div class="main-title mb30-lg">
                      <h2 class="title">All categories</h2>
                    </div>
                  </div>
                  <div class="col-lg-3"></div>
                </div>
                <div class="row">
                  {this.state.categories.map((item, index) => {
                    return (
                      <div className="col-sm-6 col-lg-4 col-xl-3" key={index}>
                        <div className="iconbox-style1 bdr1 d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="flaticon-web-design-1"></span>
                          </div>
                          <div className="details ml40">
                            <p className="mb-0 text">{item.count} Jobs</p>
                            <h5 className="title">{item.label}</h5>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Spin>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}
