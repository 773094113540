import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { message, notification, Spin, Select } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class Internship extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      current_location: "",
      internship_for: "",
      photo: "",
      resume: "",
      loading: false,
      location_list: [],
    };
  }
  componentDidMount() {
    this.get_location_list();
  }
  get_location_list = () => {
    axios
      .get(URLs.get_all_select_list)
      .then((response) => {
        if (response.data.status == "1") {
          console.log("respponse", response.data.data);
          this.setState({
            location_list: response.data.data.location,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  submitForm = () => {
    if (!this.state.name) {
      message.warning("Name is required.");
      return;
    }
    if (!this.state.email) {
      message.warning("Email Address is required.");
      return;
    }
    if (!this.state.mobile) {
      message.warning("Mobile number is required.");
      return;
    }
    if (!this.state.internship_for) {
      message.warning("Value of Internship For field is required.");
      return;
    }
    if (!this.state.current_location) {
      message.warning("Current location is required.");
      return;
    }
    if (!this.state.resume) {
      message.warning("Please select resume file");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    let isValid = emailPattern.test(this.state.email);
    if (isValid == false) {
      message.warning("Invalid email address format.");
      return;
    }
    let formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("mobile", this.state.mobile);
    formData.append("email", this.state.email);
    formData.append("current_location", this.state.current_location);
    formData.append("internship_for", this.state.internship_for);
    formData.append("photo", this.state.photo);
    formData.append("resume", this.state.resume);
    this.setState({ loading: true });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios
      .post(URLs.save_internship, formData, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              name: "",
              mobile: "",
              email: "",
              current_location: "",
              internship_for: "",
              photo: "",
              resume: "",
              loading: false,
            },
            () => {
              this.openNotification(
                "topRight",
                response.data.message,
                "success"
              );
            }
          );
        } else {
          this.setState({ loading: false });
          this.openNotification("topRight", response.data.message, "warning");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        let messageText = "Something went wrong. Please try again.";
        this.openNotification("topRight", messageText, "warning");
      });
  };

  openNotification = (placement, messageText, type) => {
    if (type == "success") {
      notification.success({
        message: messageText,
        description: <p></p>,
        placement,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      notification.warning({
        message: messageText,
        description: <p></p>,
        placement,
      });
    }
  };
  render() {
    return (
      <div className="contact-us-page">
        <Header />
        <div className="body_content" style={{ background: "#e9e8ff" }}>
          <section class="our-login">
            <div class="container">
              <div class="row">
                <div
                  class="col-lg-6 m-auto wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div class="main-title text-center">
                    <h2 class="title">Internship</h2>
                    {/* <p class="paragraph">For any issue you can contact us</p> */}
                  </div>
                </div>
              </div>
              <div class="row wow fadeInRight" data-wow-delay="300ms">
                <div class="col-xl-6 mx-auto">
                  <Spin spinning={this.state.loading}>
                    <div class="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                      <div class="mb20">
                        <label class="form-label fw600 dark-color">
                          Name
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="name"
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Mobile No.
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          placeholder=""
                          name="mobile"
                          value={this.state.mobile}
                          onChange={(e) =>
                            this.setState({ mobile: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Email
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Internship For
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          name="internship_for"
                          value={this.state.internship_for}
                          onChange={(e) =>
                            this.setState({ internship_for: e.target.value })
                          }
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Current Location
                          <span className="text-danger">*</span>
                        </label>{" "}
                        <select
                          className="form-control"
                          value={this.state.current_location}
                          onChange={(e) =>
                            this.setState({ current_location: e.target.value })
                          }
                        >
                          <option value="">Select</option>
                          {this.state.location_list &&
                            this.state.location_list.map((item, index) => {
                              return (
                                <option value={item.location_id}>
                                  {item.location_name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Resume
                          <span className="text-danger">*</span>
                          <br />
                          (Only Word file)
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          accept=".doc, .docx"
                          name="resume"
                          onChange={(e) => {
                            const allowedExtensions = /(\.doc)$/i; // Regular expression to check if file has .doc extension
                            const file = e.target.files[0];
                            if (file && allowedExtensions.test(file.name)) {
                              this.setState({
                                resume: e.target.files[0],
                              });
                            } else {
                              message.warning(
                                "Please select .doc file. Other type of files are not allowed."
                              );
                              return;
                            }
                          }}
                        />
                      </div>
                      <div class="mb15">
                        <label class="form-label fw600 dark-color">
                          Photo (If Available)
                          <br />
                          (Only JPG, PNG file)
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          accept="image/*"
                          name="photo"
                          onChange={(e) =>
                            this.setState({ photo: e.target.files[0] })
                          }
                        />
                      </div>
                      <div class="d-grid mb20">
                        <button
                          class="ud-btn btn-thm"
                          style={{ backgroundColor: "#0098da" }}
                          type="button"
                          onClick={() => this.submitForm()}
                        >
                          Submit <i class="fal fa-arrow-right-long"></i>
                        </button>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}
