import React, { Component } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { message, notification, Spin, Select } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class ApplyJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      mobile: "",
      email: "",
      experience_from: "",
      experience_to: "",
      qualification: "",
      industry: "",
      department: "",
      role: "",
      photo: "",
      loading: false,
      state_list: [],
      city_list: [],
      ctc: "",
      ctc_from: "",
      ctc_to: "",

      max_ctc_from: "",
      max_ctc_to: "",

      department_list: [],
      role_list: [],
      industry_list: [],
      ctc_list: [],
      ex_months: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      ex_year_list: [],
      experience_from: "",
      experience_in_smonth: "",
      resume: "",
      location_list: [],
      location_id: "",
      experience_list: [],
      job_id: "",
    };
  }
  setSelectData = () => {
    let experience_list = [];
    let ctc_list = [];
    for (let i = 1; i <= 30; i++) {
      experience_list.push(i);
    }
    for (let i = 0; i <= 100; i++) {
      ctc_list.push(i);
    }
    this.setState({ experience_list, ctc_list });
  };
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get("job_id");
    this.setState({
      job_id: id,
    });
    this.setSelectData();
    this.get_all_list();
    let arr = [];
    for (let i = 1; i <= 30; i++) {
      arr.push(i);
    }
    let ctc_list = [];
    for (let i = 0; i <= 100; i++) {
      ctc_list.push(i);
    }
    this.setState({
      ex_year_list: arr,
      ctc_list: ctc_list,
    });
  }
  get_all_list = () => {
    axios
      .get(URLs.get_all_select_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            department_list: response.data.data.department,
            industry_list: response.data.data.industry,
            role_list: response.data.data.role,
            location_list: response.data.data.location,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  apply = () => {
    if (!this.state.job_id) {
      message.warning("Invalid request.");
      return;
    }
    if (!this.state.full_name) {
      message.warning("Please enter value of Full name.");
      return;
    }
    if (!this.state.email) {
      message.warning("Please enter value of Email address.");
      return;
    }
    const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    let isValid = emailPattern.test(this.state.email);
    if (isValid == false) {
      message.warning("Invalid email address format.");
      return;
    }
    if (!this.state.mobile) {
      message.warning("Please enter value of Mobile no.");
      return;
    }
    if (
      this.state.current_ctc_from != "negotiable" &&
      this.state.current_ctc_from == ""
    ) {
      message.warning("Please select Current ctc from");
      return;
    }
    if (this.state.current_ctc_from != "negotiable") {
      if (!this.state.current_ctc_to) {
        message.warning("Please select Current ctc to");
        return;
      }
    }
    if (!this.state.experience_from) {
      message.warning("Please select experience.");
      return;
    } else if (!this.state.experience_to) {
      message.warning("Please select experience.");
      return;
    }
    if (!this.state.current_location) {
      message.warning("Please enter your current location.");
      return;
    }
    if (!this.state.resume) {
      message.warning("Please select resume");
      return;
    }
    let formData = new FormData();
    formData.append("job_id", this.state.job_id);
    formData.append("full_name", this.state.full_name);
    formData.append("email", this.state.email);
    formData.append("mobile", this.state.mobile);
    formData.append("current_ctc_to", this.state.current_ctc_to);
    formData.append("current_ctc_from", this.state.current_ctc_from);
    formData.append("experience_from", this.state.experience_from);
    formData.append("experience_to", this.state.experience_to);
    formData.append("current_location", this.state.current_location);
    formData.append("photo", this.state.photo);
    formData.append("resume", this.state.resume);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    this.setState({ formLoader: true });
    axios
      .post(URLs.apply_for_job, formData, config)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState(
            {
              name: "",
              designation: "",
              company_name: "",
              mobile: "",
              email: "",
              company_website: "",
              service_required: [],
              company_attachment: "",
              formLoader: false,
              modalStatus: false,
            },
            () =>
              this.openNotification(
                "topRight",
                response.data.message,
                "success"
              )
          );
        } else {
          this.setState({ formLoader: false });
          this.openNotification("topRight", response.data.message, "warning");
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        let messageText = "Something went wrong. Please try again.";
        this.openNotification("topRight", messageText, "warning");
      });
  };
  openNotification = (placement, messageText, type) => {
    if (type == "success") {
      notification.success({
        message: messageText,
        description: <p></p>,
        placement,
      });
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      notification.warning({
        message: messageText,
        description: <p></p>,
        placement,
      });
    }
  };
  onChangeFile = (e) => {
    this.setState({
      photo: e.target.files[0],
    });
  };
  onChangePhoto = (e) => {
    this.setState({
      photo: e.target.files[0],
    });
  };
  onChangeResume = (e) => {
    const allowedExtensions = /(\.doc|\.docx)$/i; // Allow both .doc and .docx extensions
    const file = e.target.files[0];
    if (file && allowedExtensions.test(file.name)) {
      this.setState({
        resume: file,
      });
    } else {
      message.warning(
        "Please select a .doc or .docx file. Other types of files are not allowed."
      );
      return;
    }
  };
  
  render() {
    return (
      <div className="contact-us-page">
        <Header />
        <div className="body_content" style={{ background: "#e9e8ff" }}>
          <section class="our-login">
            <div class="container">
              <div class="row">
                <div
                  class="col-lg-6 m-auto wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div class="main-title text-center">
                    <h2 class="title">Apply For Job</h2>
                  </div>
                </div>
              </div>
              <div class="row wow fadeInRight" data-wow-delay="300ms">
                <div class="col-xl-6 mx-auto">
                  <div class="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                    <Spin spinning={this.state.loading}>
                      <div>
                        <div class="mt30 mb20">
                          <label class="form-label fw600 dark-color">
                            Full Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                            name="full_name"
                            value={this.state.full_name}
                            onChange={(e) =>
                              this.setState({ full_name: e.target.value })
                            }
                          />
                        </div>
                        <div class="mt20 mb20">
                          <label class="form-label fw600 dark-color">
                            Mobile <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            class="form-control"
                            placeholder=""
                            name="mobile"
                            maxLength={10}
                            value={this.state.mobile}
                            onChange={(e) => {
                              let value = e.target.value.slice(0, 10);
                              this.setState({ mobile: value });
                            }}
                          />
                        </div>
                        <div class="mt20 mb20">
                          <label class="form-label fw600 dark-color">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                            name="email"
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </div>
                        <div class="mt20 mb20">
                          <label class="form-label fw600 dark-color">
                            Current CTC (In lakhs)
                            <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex justify-content-between">
                            <select
                              class="form-control"
                              name="state_id"
                              value={this.state.current_ctc_from}
                              onChange={(e) => {
                                let value = e.target.value;
                                this.setState(
                                  {
                                    current_ctc_from: e.target.value,
                                  },
                                  () => {
                                    if (value == "negotiable") {
                                      this.setState({
                                        current_ctc_to: "",
                                      });
                                    }
                                  }
                                );
                              }}
                              style={{ width: "45%" }}
                            >
                              <option value="">Select</option>
                              <option value="negotiable">Negotiable</option>
                              {this.state.ctc_list.map((item, index) => {
                                return (
                                  <option value={item} key={index}>
                                    {item} Lakh
                                  </option>
                                );
                              })}
                              <option value="100+">100+ Lakhs</option>
                            </select>
                            <div className="pt-3 text-center"></div>
                            <select
                              class="form-control"
                              name="state_id"
                              value={this.state.current_ctc_to}
                              onChange={(e) => {
                                this.setState({
                                  current_ctc_to: e.target.value,
                                });
                              }}
                              disabled={
                                this.state.current_ctc_from == "negotiable"
                                  ? true
                                  : false
                              }
                              style={{ width: "45%" }}
                            >
                              <option value="">Select</option>
                              {this.state.ctc_list.map((item, index) => {
                                return (
                                  <option value={item} key={index}>
                                    {item} Thousand
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div class="mt20 mb20">
                          <label class="form-label fw600 dark-color">
                            Experience <span className="text-danger">*</span>
                          </label>
                          <div className="d-flex justify-content-between">
                            <select
                              class="form-control"
                              name="experience"
                              value={this.state.experience_from}
                              onChange={(e) => {
                                this.setState({
                                  experience_from: e.target.value,
                                });
                              }}
                              style={{ width: "45%" }}
                            >
                              <option value="">Select</option>
                              {this.state.experience_list &&
                                this.state.experience_list.map(
                                  (item, index) => {
                                    return (
                                      <option value={item} key={index}>
                                        {item} Year
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                            <div className="text-center pt-3"></div>
                            <select
                              class="form-control"
                              name="experience"
                              value={this.state.experience_to}
                              onChange={(e) => {
                                this.setState({
                                  experience_to: e.target.value,
                                });
                              }}
                              style={{ width: "45%" }}
                            >
                              <option value="">Select</option>
                              {this.state.experience_list &&
                                this.state.experience_list.map(
                                  (item, index) => {
                                    return (
                                      <option value={item} key={index}>
                                        {item} Month
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </div>
                        </div>
                        <div class="mt20 mb20">
                          <label class="form-label fw600 dark-color">
                            Current Location{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            class="form-control"
                            name="experience"
                            value={this.state.current_location}
                            onChange={(e) => {
                              this.setState({
                                current_location: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select</option>
                            {this.state.location_list &&
                              this.state.location_list.map((item, index) => {
                                return (
                                  <option value={item.location_id} key={index}>
                                    {item.location_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div class="mt20 mb20">
                          <label class="form-label fw600 dark-color">
                            Photo(If Available)
                            <br />
                            (Only JPG, PNG)
                          </label>
                          <input
                            type="file"
                            class="form-control"
                            accept=".jpg, .png"
                            name="photo"
                            onChange={this.onChangePhoto}
                          />
                        </div>
                        <div class="mt20 mb20">
                          <label class="form-label fw600 dark-color">
                            Resume <span className="text-danger">*</span>
                            <br />
                            (Only Word File)
                          </label>
                          <input
                            type="file"
                            class="form-control"
                            accept=".doc, .docx"
                            name="resume"
                            onChange={this.onChangeResume}
                          />
                        </div>
                        <div class="d-grid mb20">
                          <button
                            class="ud-btn btn-thm"
                            style={{ backgroundColor: "#0098da" }}
                            type="button"
                            onClick={() => this.apply()}
                          >
                            Submit <i class="fal fa-arrow-right-long"></i>
                          </button>
                        </div>
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}
